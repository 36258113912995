/**
 * Created by DELL on 2019/4/22.
 */
/**风速换算 */
export function  WindLevel(wv){
        var level = "";
        if (wv >= 0.0 && wv <= 0.2)
        {
            level = "无风";
        }
        else if (wv >= 0.3 && wv <= 1.5)
        {
            level = "1级";
        }
        else if (wv >= 1.6 && wv <= 3.3)
        {
            level = "2级";
        }
        else if (wv >= 3.4 && wv <= 5.4)
        {
            level = "3级";
        }
        else if (wv >= 5.5 && wv <= 7.9)
        {
            level = "4级";
        }
        else if (wv >= 8.0 && wv <= 10.7)
        {
            level = "5级";
        }
        else if (wv >= 10.8 && wv <= 13.8)
        {
            level = "6级";
        }
        else if (wv >= 13.9 && wv <= 17.1)
        {
            level = "7级";
        }
        else if (wv >= 17.2 && wv <= 20.7)
        {
            level = "8级";
        }
        else if (wv >= 20.8 && wv <= 24.4)
        {
            level = "9级";
        }
        else if (wv >= 24.5 && wv <= 28.4)
        {
            level = "10级";
        }
        else if (wv >= 28.5 && wv <= 32.6)
        {
            level = "11级";
        }
        else if (wv >= 32.7 && wv <= 36.9)
        {
            level = "12级";
        }
        else if (wv >= 37.0 && wv <= 41.4)
        {
            level = "13级";
        }
        else if (wv >= 41.5 && wv <= 46.1)
        {
            level = "14级";
        }
        else if (wv >= 46.2 && wv <= 50.9)
        {
            level = "15级";
        }
        return level;
    }
/**风速换算 */
export function  WindLevel1(wv){
        var level = "";
        if (wv >= 0.0 && wv <= 0.2)
        {
            level = 0;
        }
        else if (wv >= 0.3 && wv <= 1.5)
        {
            level = 1;
        }
        else if (wv >= 1.6 && wv <= 3.3)
        {
            level = 2;
        }
        else if (wv >= 3.4 && wv <= 5.4)
        {
            level = 3;
        }
        else if (wv >= 5.5 && wv <= 7.9)
        {
            level = 4;
        }
        else if (wv >= 8.0 && wv <= 10.7)
        {
            level = 5;
        }
        else if (wv >= 10.8 && wv <= 13.8)
        {
            level = 6;
        }
        else if (wv >= 13.9 && wv <= 17.1)
        {
            level = 7;
        }
        else if (wv >= 17.2 && wv <= 20.7)
        {
            level = 8;
        }
        else if (wv >= 20.8 && wv <= 24.4)
        {
            level = 9;
        }
        else if (wv >= 24.5 && wv <= 28.4)
        {
            level = 10;
        }
        else if (wv >= 28.5 && wv <= 32.6)
        {
            level = 11;
        }
        else if (wv >= 32.7 && wv <= 36.9)
        {
            level = 12;
        }
        else if (wv >= 37.0 && wv <= 41.4)
        {
            level = 13;
        }
        else if (wv >= 41.5 && wv <= 46.1)
        {
            level = 14;
        }
        else if (wv >= 46.2 && wv <= 50.9)
        {
            level = 15;
        }
        return level;
    }
export function Getfengxiang(fx1) {
    var fx3 = "";
    if (fx1 > 0 && fx1 < 11.25) {
        fx3 = "北风";
    }
    else if (fx1 > 11.26 && fx1 < 33.75) {
        fx3 = "东北风";
    }
    else if (fx1 > 33.76 && fx1 < 56.25) {
        fx3 = "东北风";
    }
    else if (fx1 > 56.26 && fx1 < 78.75) {
        fx3 = "东北风";
    }
    else if (fx1 > 78.76 && fx1 < 101.25) {
        fx3 = "东风";
    }
    else if (fx1 > 101.25 && fx1 < 123.75) {
        fx3 = "东南风";
    }
    else if (fx1 > 123.76 && fx1 < 146.25) {
        fx3 = "东南风";
    }
    else if (fx1 > 146.26 && fx1 < 168.75) {
        fx3 = "东南风";
    }
    else if (fx1 > 168.76 && fx1 < 191.25) {
        fx3 = "南风";
    }
    else if (fx1 > 191.26 && fx1 < 213.75) {
        fx3 = "西南风";
    }
    else if (fx1 > 213.76 && fx1 < 236.25) {
        fx3 = "西南风";
    }
    else if (fx1 > 236.26 && fx1 < 258.75) {
        fx3 = "西南风";
    }
    else if (fx1 > 258.76 && fx1 < 281.25) {
        fx3 = "西风";
    }
    else if (fx1 > 281.76 && fx1 < 303.75) {
        fx3 = "西北风";
    }
    else if (fx1 > 303.76 && fx1 < 326.25) {
        fx3 = "西北风";
    }
    else if (fx1 > 326.26 && fx1 < 348.75) {
        fx3 = "西北风";
    }
    else { fx3 = "北风"; }

    return fx3;
}

export function Getfengsu(fs1) {
    var fs3 = "",fj;
    if (fs1 > 0 && fs1 < 0.2) {
        fs3 = "无风";
        fj = 0;
    }
    else if (fs1 > 0.3 && fs1 < 1.5) {
        fs3 = "软风";
        fj = 1;
    }
    else if (fs1 > 1.6 && fs1 < 3.3) {
        fs3 = "轻风";
        fj = 2;
    }
    else if (fs1 > 3.4 && fs1 < 5.4) {
        fs3 = "微风";
        fj = 3;
    }
    else if (fs1 > 5.5 && fs1 < 7.9) {
        fs3 = "和风";
        fj = 4;
    }
    else if (fs1 > 8.0 && fs1 < 10.7) {
        fs3 = "轻劲风";
        fj = 5;
    }
    else if (fs1 > 10.8 && fs1 < 13.8) {
        fs3 = "强风";
        fj = 6;
    }
    else if (fs1 > 13.9 && fs1 < 17.1) {
        fs3 = "疾风";
        fj = 7;
    }
    else if (fs1 > 17.2 && fs1 < 20.7) {
        fs3 = "大风";
        fj = 8;
    }
    else if (fs1 > 20.8 && fs1 < 24.4) {
        fs3 = "烈风";
        fj = 9;
    }
    else if (fs1 > 24.5 && fs1 < 28.4) {
        fs3 = "狂风";
        fj = 10;
    }
    else if (fs1 > 28.5 && fs1 < 32.6) {
        fs3 = "暴风";
        fj = 11;
    }
    else if (fs1 > 32.7 && fs1 < 36.9) {
        fs3 = "台风";
        fj = 12;
    }
    else { fs3 = "微风";fj = 3; }
    return {"name":fs3,"value":fj};
}